import { navigate } from '@reach/router'
import React from 'react'

import { NotFoundLayout } from '../components/Layout'
import Seo from '../components/Seo'

const NotFoundPage = () => (
  <NotFoundLayout>
    <Seo title={'404: Not Found'} />
    <h1 className='center' style={{ marginTop: '50px' }}>
      404 NOT FOUND
    </h1>
    <p className='center'>You just hit a route that does not exist.</p>
    <div className='center'>
      <button className='btn btn-primary btn-lg' onClick={async () => navigate('/')}>
        {' '}
        Go Home
      </button>
    </div>
  </NotFoundLayout>
)

export default NotFoundPage
